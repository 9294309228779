import { generateObjectId } from '../../utils';

const getChoiceEnabled = (state: any, block: any) => {
  const otherChoiceEmpty = state.some(
    (item: any) => typeof item === 'object' && !item.text,
  );
  return !otherChoiceEmpty && state.length >= (block.content.min || 1);
};
const customVideoEnabled = (_, block) => {
  return block.watched;
};
const getSmartGoalEnabled = (state, block) => {
  if (Object.keys(state).length === 0) {
    return false;
  }
  const choiceIds = block.content.items.flatMap(item =>
    item.choices.map(choice => choice.id),
  );
  return choiceIds.every(choiceId => !!state[choiceId]);
};

const getPersonaSelectionEnabled = (state, block) => {
  return !!state && !!block.watched;
};

const getFileUploadEnabled = (state, block) => {
  return state.length === block.content.options.length;
};

const getFreeTextEnabled = state => {
  const cleanedState = state.replace(/<[^>]*>?/gm, '');
  return cleanedState.length > 0;
};

const getCalendarEnabled = (state, block) => {
  const choicesLength = block.settings?.choices?.length || 1;
  return state?.events?.length >= choicesLength;
};

const getMatchDragEnabled = (state, block) => {
  if (Object.values(state).length === 0) {
    return false;
  }
  return block.content.matches.some(
    match => !!state[match.id] && state[match.id].length,
  );
};

const isEmptyTask = value => {
  return (
    (typeof value === 'string' || Array.isArray(value)) && value.length > 0
  );
};

const getProjectPlanEnabled = state => {
  const tasksLine = ['criteria', 'estimation', 'tasks', 'resources'];
  return state.every(obj =>
    tasksLine.every(property => isEmptyTask(obj[property])),
  );
};

const getPlanControlEnabled = (state, block) => {
  return block.content.options.every(option => {
    const goal = state.find(item => item.id === option.id);
    return (
      goal?.status &&
      (['done', 'on_track'].includes(goal.status) ||
        (goal.reason.length && getProjectPlanEnabled([goal])))
    );
  });
};

const getGanttEnabled = (state, block) => {
  if (!block.content.droppable) {
    return true;
  }
  return state.tasks.length > 0;
};

const getTextAreaEnabled = (state, block) => {
  return state.filter(item => !!item.text).length >= (block.content.min || 1);
};

const getText = answer => {
  return typeof answer === 'string' ? answer : answer?.text;
};

const getProjectPlanContent = ({ submissions }) => {
  if (!submissions?.length) {
    return {};
  }
  let typedSubmissions =
    typeof submissions === 'string' ? [submissions] : submissions;
  return {
    options: typedSubmissions.map((submission, index) => ({
      id: `${index + 1}`,
      title: getText(submission),
    })),
  };
};

const getDraggableTableEnabled = (state, block) => {
  return !block.content.items.some((blockItem, index) => {
    return block.content.headers.some(header => {
      const contentArray = blockItem[header.key].content;
      const hasDroppableContents = contentArray.some(item => item.droppable);
      return hasDroppableContents && !state?.[index]?.[header.key]?.length;
    });
  });
};

const getPlanControlContent = ({ submissions }) => {
  return submissions?.length ? { options: submissions } : {};
};

const getGanttContent = ({ submissions }) => {
  const tasks = submissions.flatMap(submission => submission.tasks);
  return { tasks };
};

const getCalendarDragContent = ({ submissions }) => {
  const options = submissions.flatMap(submission => submission.tasks);
  return { options };
};

const getTableContent = ({ submissions, block }) => {
  if (!submissions.length) {
    return {};
  }
  const items = submissions.map(submission => {
    return block.content.headers.reduce((item, header) => {
      const propertyKey = header.key;
      const propertyValue = submission[propertyKey];
      const splitText = Array.isArray(propertyValue)
        ? propertyValue
        : propertyValue.split('\n');
      return {
        ...item,
        [propertyKey]: {
          content: splitText.map(text =>
            text.title ? { text: text.title } : { text },
          ),
        },
      };
    }, {});
  });
  return { items };
};

const getChartContent = ({ submissions }) => {
  if (!submissions.length) {
    return {};
  }
  const data = {
    labels: submissions.map(({ text }) => text),
    datasets: [{ data: submissions.map(({ duration }) => duration / 60) }],
  };
  return { data };
};

const getPriorityQuadrantEnabled = (state, block) => {
  const count = state.reduce((rowAcc, row) => {
    const rowLengths = block.content.headers.reduce((acc, header) => {
      return acc + row[header.key].length;
    }, 0);
    return rowAcc + rowLengths;
  }, 0);
  return count === block.content.options.length;
};

const getTimeEstimationEnabled = (state, block) => {
  const totalDuration = state.reduce((sum, task) => sum + task.duration, 0);
  const hasInvalidTasks = state.some(
    task => task.duration === 0 || task.text.trim() === '',
  );
  return totalDuration / 60 === block.content.totalHours && !hasInvalidTasks;
};

const getPersonaSelectionContent = ({ submissions }) => {
  return { selectable: !submissions };
};
const pairingBlockEnabled = (state, block) => {
  return block.content.matches.length === Object.values(state).length;
};
const audioPlayerEnabled = state => {
  return !!state?.file;
};

const audioListenerEnabled = (state, block) => {
  return block.watched;
};

const fillBlanksEnabled = state => {
  return Object.values(state).every((item: any) => item.length && !!item[0]);
};

const getPriorityQuadrantContent = ({ submissions }) => {
  if (!submissions.length) {
    return {};
  }
  return {
    options: submissions.map((submission, index) => ({
      id: `${index + 1}`,
      title: submission.text,
    })),
  };
};

const getEvaluationTableContent = ({
  submissions,
  type = 'items',
  previousBlock,
}) => {
  if (type === 'headers') {
    return {
      headers: [
        { key: 'title', title: '' },
        ...submissions.map(submission => ({
          key: submission.id,
          title: submission.text,
        })),
      ],
    };
  }
  let items = submissions;
  if (previousBlock?.name === 'textarea') {
    items = submissions.map(submission => ({
      title: {
        id: submission.id,
        content: [{ text: submission.text, id: 1 }],
      },
    }));
  }

  return { items };
};

const getWeightingTableContent = ({
  submissions,
  type = 'items',
  previousBlock,
  block,
}) => {
  if (type === 'headers') {
    return {
      headers: [
        { key: 'title', title: '', type: 'decision_rank' },
        ...submissions.map(submission => ({
          key: submission.id,
          title: submission.text,
          type: 'decision_score',
        })),
      ],
    };
  }
  let items = submissions;
  if (previousBlock?.name === 'advanced_table') {
    items = submissions.map(submission => {
      const newItem = {
        title: {
          id: submission.id,
          content: [
            {
              text: submission.consideration.content[0].text,
              value: submission.weight.content[0].value,
              id: 1,
            },
          ],
        },
      };
      block.content.headers.forEach((header, headerIndex) => {
        newItem[header.key] = newItem[header.key] || {
          id: headerIndex,
          content: [{ id: generateObjectId(), text: '', value: 0 }],
        };
      });
      return newItem;
    });
  }
  return { items };
};
const getTableEnabled = (state, block) =>
  state.every(item =>
    block.content.headers.every((header: any) =>
      item[header.key].content.every(
        contentItem => contentItem.text.trim() !== '',
      ),
    ),
  );

const getEvaluationTableEnabled = (state, block) => {
  if (!block.content.tickable) {
    return getTableEnabled(state, block);
  }
  return state.every(item =>
    block.content.headers.some((header: any) =>
      item[header.key].content.some(contentItem => contentItem.checked),
    ),
  );
};

const getDynamicTableEnabled = (state, block) =>
  state.every(
    item => item.text.trim() !== '' && getTableEnabled(item.content, block),
  );
const getAdvancedTableContent = ({ submissions, block }) => {
  let items = submissions.map(option => {
    let content = { id: option.id };
    block.content.headers.forEach(header => {
      content[header.key] = {
        id: generateObjectId(),
        content:
          header.type === 'select'
            ? [{ id: generateObjectId(), text: '', value: 0 }]
            : [{ id: generateObjectId(), text: option.text }],
      };
    });
    return content;
  });
  return { items: items.length ? items : [] };
};

const getDynamicTableContent = ({ submissions, block }) => {
  let items = submissions.map(option => {
    let content = option.content || [{ id: generateObjectId() }];
    content = content.map(item => {
      const newItem = { ...item };
      block.content.headers.forEach(header => {
        newItem[header.key] = newItem[header.key] || {
          id: generateObjectId(),
          content: [{ id: generateObjectId(), text: '' }],
        };
      });
      return newItem;
    });
    return {
      id: option.id,
      text: option.text,
      content,
    };
  });
  return { items: submissions.length ? items : [] };
};

const highlightEnabled = state => {
  const hasMark = /<mark\b[^>]*>/i.test(state);
  return hasMark;
};

const wordCloudEnabled = (state, block) => {
  return state?.length >= block?.content?.minAnswers;
};

const pollEnabled = state => {
  return !!state?.length;
};

const getDisclaimerContent = ({ submissions }) => {
  return submissions?.length
    ? { text: `להזכירך המטרה שלך היא: ${submissions[0]?.text}` }
    : {};
};

const getChoiceContent = ({ submissions, previousBlock }) => {
  if (!submissions.length) return { options: [] };
  const isTextarea = previousBlock?.name === 'textarea';
  const options = isTextarea
    ? submissions.map(({ text, id }) => ({ title: text, id }))
    : previousBlock.content?.options.filter(({ id }) =>
        submissions.includes(id),
      );

  return { options };
};

const getRichTextContent = ({ submissions, previousBlock }) => {
  const isTextarea = previousBlock.name === 'textarea';
  if (isTextarea) {
    const data = {
      type: 'doc',
      content: submissions.map(submission => ({
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: submission.text,
          },
        ],
      })),
    };
    return { data };
  }
  return { data: submissions };
};

const getTextAreaContent = ({ submissions, previousBlock }) => {
  const isTextarea = previousBlock.name === 'textarea';
  if (isTextarea) {
    return {
      options: submissions.map(({ text, id }) => ({ title: '', id, text })),
    };
  }

  return { items: submissions };
};

const getPlanCheckEnabled = (state, block) => {
  return block.content.options.every(option => {
    const goal = state.find(item => item.id === option.id);
    return !!goal?.status;
  });
};

const getPlanCheckContent = ({ submissions }) => {
  return submissions?.length ? { options: submissions } : {};
};

const getSortEnabled = state => {
  if (!state.length) return false;
  return true;
};

const getWordCloudContent = ({ block, submissions }) => {
  const wordCounts = submissions
    ?.flatMap(submission => submission.answer)
    .reduce((acc, text) => {
      acc[text] = (acc[text] || 0) + 1;
      return acc;
    }, {});

  const words = Object.entries(wordCounts).map(([text, count]) => ({
    text,
    count,
  }));
  return { ...block.content, words };
};

const getPollContent = ({ block, submissions }) => {
  const answerCounts = submissions
    ?.flatMap(submission => submission.answer)
    .reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});

  const answers = block.content.options.map(option => ({
    id: option.id,
    title: option.title || '',
    count: answerCounts?.[option.id] || 0,
    currentUserVote: submissions.some(submission =>
      submission.answer.includes(option.id),
    ),
  }));

  return { ...block.content, answers };
};

const getCollaborativeContent = ({ block }) => {
  return { ...block.content };
};

export {
  getChoiceEnabled,
  customVideoEnabled,
  getSmartGoalEnabled,
  getPersonaSelectionEnabled,
  getFileUploadEnabled,
  getFreeTextEnabled,
  getCalendarEnabled,
  getMatchDragEnabled,
  isEmptyTask,
  getProjectPlanEnabled,
  getPlanControlEnabled,
  getGanttEnabled,
  getTextAreaEnabled,
  getText,
  getProjectPlanContent,
  getDraggableTableEnabled,
  getPlanControlContent,
  getGanttContent,
  getCalendarDragContent,
  getTableContent,
  getChartContent,
  getPriorityQuadrantEnabled,
  getTimeEstimationEnabled,
  getPersonaSelectionContent,
  pairingBlockEnabled,
  audioPlayerEnabled,
  audioListenerEnabled,
  fillBlanksEnabled,
  getPriorityQuadrantContent,
  getEvaluationTableContent,
  getWeightingTableContent,
  getTableEnabled,
  getEvaluationTableEnabled,
  getDynamicTableEnabled,
  getAdvancedTableContent,
  getDynamicTableContent,
  highlightEnabled,
  wordCloudEnabled,
  pollEnabled,
  getDisclaimerContent,
  getChoiceContent,
  getRichTextContent,
  getTextAreaContent,
  getPlanCheckEnabled,
  getPlanCheckContent,
  getSortEnabled,
  getWordCloudContent,
  getPollContent,
  getCollaborativeContent,
};
