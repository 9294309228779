import { get } from 'lodash';
import {
  getProjectPlanContent,
  getPlanControlContent,
  getGanttContent,
  getCalendarDragContent,
  getTableContent,
  getChartContent,
  getPersonaSelectionContent,
  getPriorityQuadrantContent,
  getEvaluationTableContent,
  getWeightingTableContent,
  getAdvancedTableContent,
  getDynamicTableContent,
  getDisclaimerContent,
  getChoiceContent,
  getRichTextContent,
  getTextAreaContent,
  getPlanCheckContent,
  getWordCloudContent,
  getPollContent,
  getCollaborativeContent,
} from '@/apps/slideshow/stateMapperFunctions';

interface ContentParams {
  block: any;
  submissions: any;
  previousBlock?: any;
  type?: string;
}

export function useStateMapper() {
  const handlers = {
    choice: {
      initialValue: () => [],
      content: getChoiceContent,
    },
    free_text: {
      initialValue: () => '',
    },
    match_drag: {
      initialValue: () => ({}),
    },
    persona_selection: {
      initialValue: block => block.content?.personas?.[0]?.id,
      content: getPersonaSelectionContent,
    },
    calendar_drag: {
      initialValue: () => ({ events: [] }),
      content: getCalendarDragContent,
    },
    highlight: {
      initialValue: () => '',
    },
    pairing: {
      initialValue: () => ({}),
    },
    smart_goal: {
      initialValue: submissions => submissions || {},
    },
    match: {
      initialValue: submissions => submissions || {},
    },
    rich_text: {
      initialValue: submissions => submissions || '',
      content: getRichTextContent,
    },
    project_plan: {
      initialValue: submissions => submissions || [],
      content: getProjectPlanContent,
    },
    plan_control: {
      initialValue: submissions => submissions || [],
      content: getPlanControlContent,
    },
    plan_check: {
      initialValue: submissions => submissions || [],
      content: getPlanCheckContent,
    },
    file_upload: {
      initialValue: submissions => submissions || [],
    },
    draggable_table: {
      initialValue: submissions => submissions || [],
    },
    gantt: {
      initialValue: submissions => submissions || { tasks: [] },
      content: getGanttContent,
    },
    sort: {
      initialValue: submissions => submissions || [],
    },
    textarea: {
      initialValue: submissions => submissions || [],
      content: getTextAreaContent,
    },
    priority_quadrant: {
      initialValue: submissions => submissions || [],
      content: getPriorityQuadrantContent,
    },
    audio_player: {
      initialValue: submissions => submissions || { file: '' },
    },
    fill_blanks: {
      initialValue: submissions => submissions || {},
    },
    time_estimation: {
      initialValue: submissions => submissions || [],
    },
    chart: {
      content: getChartContent,
    },
    table: {
      content: getTableContent,
    },
    audio_listener: {
      initialValue: submissions => submissions || { src: '' },
    },
    word_cloud: {
      initialValue: submissions => submissions || [],
      content: getWordCloudContent,
    },
    poll: {
      initialValue: submissions => submissions || [],
      content: getPollContent,
    },
    disclaimer: {
      content: getDisclaimerContent,
    },
    dynamic_table: {
      initialValue: submissions => submissions || [],
      content: getDynamicTableContent,
    },
    evaluation_table: {
      initialValue: submissions => submissions || [],
      content: getEvaluationTableContent,
    },
    weighting_table: {
      initialValue: submissions => submissions || [],
      content: getWeightingTableContent,
    },
    advanced_table: {
      initialValue: submissions => submissions || [],
      content: getAdvancedTableContent,
    },
    collaborative_board: {
      initialValue: submissions => submissions || [],
      content: getCollaborativeContent,
    },
  };

  const getHandler = (name, action, fallback: any) => {
    return get(handlers, `${name}.${action}`, fallback);
  };

  const canContinue = (block, state) => {
    const handler: any = getHandler(block.name, 'enabled', null);
    if (!handler) {
      return true;
    }
    return handler(state, block);
  };

  const toState = (block, submissions) => {
    const handler: any = getHandler(block.name, 'initialValue', () => []);
    if (handler.length > 0) {
      return handler(submissions, block);
    }
    return handler(block);
  };

  const toContent = ({
    block,
    submissions,
    previousBlock,
    type,
  }: ContentParams) => {
    if (!block.name) return;
    const handler: any = getHandler(block.name, 'content', () => ({}));
    return handler({ submissions, block, previousBlock, type });
  };

  return {
    canContinue,
    toState,
    toContent,
  };
}
